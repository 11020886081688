import BlockchainContext from "../store/BlockchainContext";
import { useContext, useEffect, useState } from "react";
import HeaderSection from "./HeaderSection";
import FooterSection from "./FooterSection";
import StakeSection from "./StakeSection";
import { walletAddressSlicer, numberWithCommas } from "../utils/util";
import { toast } from "react-toastify";
import FeatureSection from "./FeatureSection";
import BuyABot from "./BuyABot";

const Home = () => {
  
  const d = document;

  const [amount = 0.01, setAmount] = useState();  // Monto del Donate
  const { handleDonate, donatepending, donateText, approveBusd, array } =
    useContext(BlockchainContext);

  const donate = () => {
    if (new Date().getTime() > 1652554800000) {
      if (donateText === "Enable") {
        approveBusd(account);
      } else {
        if (Number(amount) > 0) {
          handleDonate(amount);
        } else {
          toast.error("Enter valid amount", {
            position: "bottom-right",
          });
        }
      }
    } else {
      toast.warn("Launch date is Saturday 14th May 2022 at 7pm UTC", {
        position: "bottom-right",
      });
    }
  };

  const donateUSDT = () => {
    d.querySelector(".donateBNB").classList.add("display-none");
    d.querySelector(".donateBNB").classList.add("v-none");
    d.querySelector(".donateUSDT").classList.remove("display-none");
    d.querySelector(".donateUSDT").classList.remove("v-none");

    d.querySelector(".bnb-button").classList.remove("selected");
    d.querySelector(".usdt-button").classList.add("selected");
  }

  const donateBNB = () => {
    d.querySelector(".donateUSDT").classList.add("display-none");
    d.querySelector(".donateUSDT").classList.add("v-none");
    d.querySelector(".donateBNB").classList.remove("display-none");
    d.querySelector(".donateBNB").classList.remove("v-none");

    d.querySelector(".bnb-button").classList.add("selected");
    d.querySelector(".usdt-button").classList.remove("selected");
  }

  const [open, setOpen] = useState(false);
  const { handleConnectToWallet, account, handleDisconnectWallet } =
    useContext(BlockchainContext);


  return (

    
    <div className="minh-100">

<div className="sticky top-0 z-40 w-full border-b border-gray-200 backdrop-blur flex-none transition-colors duration-500 lg:z-50 lg:border-b lg:border-slate-900/10 bg-white/95 supports-backdrop-blur:bg-white/60 header abajo">
        

        <div className="max-w-7xl mx-auto px-4 sm:px-6">
          <div className="flex justify-between items-center py-6 md:justify-start md:space-x-10">
            <div className="flex justify-start lg:w-0 lg:flex-1"></div>
            <div className="-mr-2 -my-2 md:hidden">
              <button
                className="menu-btn border border-slat hover:header-a-hovere-200 rounded-md p-2 inline-flex items-center justify-center text-black"
                id="headlessui-popover-button-1"
                type="button"
                aria-expanded="false"
                onClick={() => setOpen(true)}
              >
                <span className="sr-only">OPEN MENU</span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="2"
                  stroke="currentColor"
                  aria-hidden="true"
                  className="h-6 w-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M4 6h16M4 12h16M4 18h16"
                  ></path>
                </svg>
              </button>
            </div>
            <nav className="hidden md:flex space-x-7">
              <a
                href="#buyabot"
                className="text-base text-white bold hover:header-a-hover color-w menu-a"
              >
                BUY A BOT
              </a>
              <a
                href="#yourbots"
                className="text-base text-white bold hover:header-a-hover color-w menu-a"
              >
                YOUR BOTS
              </a>
              <a
                href="#botinfo"
                className="text-base text-white bold hover:header-a-hover color-w menu-a"
              >
                BOT INFO
              </a>
              <a
                // target="_blank"
                // href="/whitepaper.pdf"
                className="text-base text-white cursor-pointer bold hover:header-a-hover color-w menu-a"
              >
                WHITEPAPER
              </a>
              <a
                href={process.env.REACT_APP_BNB_FACTOR_CONRACT}
                target="_blank"
                className="text-base text-white bold hover:header-a-hover color-w menu-a"
              >
                CONTRACT
              </a>
            </nav>
            <div className="hidden md:flex items-center justify-end md:flex-1 lg:w-0">
              {account ? (
                <button
                  onClick={() => handleDisconnectWallet("wallet")}
                  className="ml-8 whitespace-nowrap inline-flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base text-black bold connect br-5 color-b"
                >
                  {walletAddressSlicer(account)}
                </button>
              ) : (
                <button
                  onClick={() => handleConnectToWallet()}
                  className="ml-8 whitespace-nowrap inline-flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base text-black bold connect br-5 color-b"
                >
                  Connect to Wallet
                </button>
              )}
            </div>
          </div>
        </div> 

        {open && (
          <div
            className="absolute top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden"
            id="headlessui-popover-panel-2"
          >
            <div className="menu br-5 shadow-lg ring-1 ring-black ring-opacity-5 bg-white divide-y-2 divide-gray-50">
              <div className="pt-5 pb-6 px-5">
                <div className="flex items-center justify-between menu-div">
                  <div></div>
                  <div className="-mr-2">
                    <button
                      className="x br-5 p-2 inline-flex items-center justify-center text-white focus:outline-none"
                      type="button"
                      tabindex="0"
                      onClick={() => setOpen(false)}
                    >
                      <span className="sr-only">Close menu</span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth="2"
                        stroke="currentColor"
                        aria-hidden="true"
                        className="h-6 w-6"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M6 18L18 6M6 6l12 12"
                        ></path>
                      </svg>
                    </button>
                  </div>
                </div>
                <div className="mt-6">
                  <nav className="grid gap-y-8">
                    <a
                      href="#buyabot"
                      className="-m-3 p-3 flex items-center br-5 cursor-pointer a-menu"
                    >
                      <span className="ml-3 text-base bold text-black color-w menu-a">
                        BUY A BOT
                      </span>
                    </a>
                    <a
                      href="#yourbots"
                      className="-m-3 p-3 flex items-center br-5 cursor-pointer a-menu"
                    >
                      <span className="ml-3 text-base bold text-black color-w menu-a">
                        YOUR BOTS
                      </span>
                    </a>
                    <a
                      href="#botinfo"
                      className="-m-3 p-3 flex items-center br-5 cursor-pointer a-menu"
                    >
                      <span className="ml-3 text-base bold text-black color-w menu-a">
                        BOT INFO
                      </span>
                    </a>
                    <a
                      // href="/whitepaper.pdf"
                      // target="_blank"
                      className="-m-3 p-3 flex items-center br-5 cursor-pointer a-menu"
                    >
                      <span className="ml-3 text-base bold text-black color-w menu-a">
                        WHITEPAPER
                      </span>
                    </a>
                    <a
                      href={process.env.REACT_APP_BNB_FACTOR_CONRACT}
                      target="_blank"
                      className="-m-3 p-3 flex items-center br-5 cursor-pointer a-menu"
                    >
                      <span className="ml-3 text-base bold text-black color-w menu-a">
                        CONTRACT
                      </span>
                    </a>
                  </nav>
                </div>
              </div>
              <div className="py-6 px-5 space-y-6">
                <div>
                  {account ? (
                    <button
                      onClick={() => handleDisconnectWallet("wallet")}
                      className="w-full flex items-center justify-center px-4 py-2 border border-transparent br-5 text-base font-medium connect-mobile text-black bold color-b"
                    >
                      {walletAddressSlicer(account)}
                    </button>
                  ) : (
                    <button
                      onClick={() => handleConnectToWallet()}
                      className="w-full flex items-center justify-center px-4 py-2 border border-transparent br-5 text-base font-medium connect-mobile text-black bold color-b"
                    >
                      CONNECT TO WALLET
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>

      {/* MAIN */}

        <div className="mas-grande">

          {/* WELCOME */}

            <div>
              <h1
                className="text-white font-extrabold text-4xl sm:text-5xl lg:text-6xl tracking-tight text-center white-none margint color-w padding-costados"
                style={{ marginTop: "0", lineHeight: "1.3" }}
              >
                Start Earning Daily{" "}
                <span className="text-violetaOscuro">2% </span>to{" "}
                <span className="text-violetaOscuro">3% </span> of <br></br>Your <span className="text-violeta">Investment</span> in 
                <div className="coming-bots" style={{marginTop: "20px", marginBottom: "12.5px"}}>
                  <img src="./assets/img/bnb-logo.png" width={"43px"} height={"45px"}></img>
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 2000 2000" width="43" height="45"><path d="M1000,0c552.26,0,1000,447.74,1000,1000S1552.24,2000,1000,2000,0,1552.38,0,1000,447.68,0,1000,0" fill="#53ae94" /><path d="M1123.42,866.76V718H1463.6V491.34H537.28V718H877.5V866.64C601,879.34,393.1,934.1,393.1,999.7s208,120.36,484.4,133.14v476.5h246V1132.8c276-12.74,483.48-67.46,483.48-133s-207.48-120.26-483.48-133m0,225.64v-0.12c-6.94.44-42.6,2.58-122,2.58-63.48,0-108.14-1.8-123.88-2.62v0.2C633.34,1081.66,451,1039.12,451,988.22S633.36,894.84,877.62,884V1050.1c16,1.1,61.76,3.8,124.92,3.8,75.86,0,114-3.16,121-3.8V884c243.8,10.86,425.72,53.44,425.72,104.16s-182,93.32-425.72,104.18" fill="#fff" /></svg>
                    <img src="./assets/img/binance-usd-busd-logo.png" width={"40px"} height={"45px"}></img>
                    <img src="./assets/img/solana-sol-logo.png" width={"38px"} style={{height: "43px"}}></img>
                    <img src="./assets/img/eth.png" width={"38px"} style={{height: "42px", position: "relative", bottom: "2px", objectFit: "inherit"}}></img>
                </div>
                with
              </h1>
              <img src="./assets/img/ninja.png" className="welcome-img floating"></img>
              <p className="mt-6 text-lg text-white text-center max-w-3xl mx-auto p-home color-w">
                Invest your <span className="text-verdeClaro bold">Crypto</span> and get
                daily profits with{" "}
                <span className="text-azul bold" style={{color: "var(--solana)"}}>Ninja Bot Trader!</span>
              </p>
            </div>

          {/* BUY A BOT */}

          <BuyABot />

          {/* YOUR BOTS */}

            <StakeSection />

            <FeatureSection />

        </div>

      {/* FOOTER */}

      <FooterSection />

    </div>
  );
};

export default Home;
