import { useContext, useEffect, useState } from "react";
import BlockchainContext from "./store/BlockchainContext";
import axios from "axios";
import Countdown from "react-countdown";
import StakeSection from "./components/StakeSection";
import BoxesSection from "./components/BoxesSection";
import WelcomeSection from "./components/WelcomeSection";
import HeaderSection from "./components/HeaderSection";
import DonateSection from "./components/DonateSection";
import FooterSection from "./components/FooterSection";
import NetworkModal from "./components/NetworkModal";
import FeatureSection from "./components/FeatureSection";
import Home from "./components/Home";

const HomeScreen = () => {
  const { networkOpen, array } = useContext(BlockchainContext);
  const [links, setLinks] = useState();

  const [contract, web3, acc] = array;

  let random = Math.random(); // REFERIDOS
  useEffect(() => {
    document.addEventListener("DOMContentLoaded", e => {
      document.querySelector("body").style.zIndex = -1;
    })

    let getAddress = window.location.href.split("?ref=")[1];
    const getRandom = () => (random <= 0.4) ? localStorage.setItem("baseRef", getAddress) : localStorage.setItem("baseRef", "0xed7eB2501921e43c34031Df1F4e6732c77D89fc5");
    if (window.location.href.includes("0x0a61D672DB25cAc6bb653442A8360F6774DaD057")) getRandom();
    if (window.location.href.includes("?ref=") && !window.location.href.includes("0x0a61D672DB25cAc6bb653442A8360F6774DaD057")) {
      localStorage.setItem("baseRef", getAddress);
    }
    if (!getAddress) {
      getAddress = "0xed7eB2501921e43c34031Df1F4e6732c77D89fc5";
      localStorage.setItem("baseRef", "0xed7eB2501921e43c34031Df1F4e6732c77D89fc5");
    }
    fetchLinks();
  }, []);

  ////////////////////////////////////////

  const fetchLinks = async () => {
    const response = await axios.get("/config.json");
    setLinks(response.data.urls);
  };

  const _balance = async () => {
    if (!acc || acc === undefined || acc == "0") return;
    await contract.methods
      .users(acc)
      .call(
        (error, result) => localStorage.setItem("balance", web3.utils.fromWei(result[0].toString()))
      );
  };

  const _getBotLevel = async () => {
    if (!acc || acc === undefined) return;
    await contract.methods
      .getMyBotLevel(acc)
      .call(
        (error, result) => {
          localStorage.setItem("web_cache_ls11", result)
        }
      );
  };

  _balance()

  let balance = localStorage.getItem("balance") 

  _getBotLevel();
  
  let botLevel = localStorage.getItem("web_cache_ls11");
  
  // const setBot = () => {
  //   if (acc && Number(botLevel) > 0) localStorage.setItem("xf1", "1"); // Esto define si una persona compro un bot
  // }

  // setBot();

  const rendererCountdown = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
      return "";
    } else {
      return (
        <div className="text-center p-4">
          {" "}
          <p className="text-center font-size19">
            Launch date is Saturday 14th May 2022 at 7pm UTC
          </p>
          <p className="timer text-center font-size40">
            <span className="days">{String(days).padStart(2, "0")}</span> :{" "}
            <span className="hours">{String(hours).padStart(2, "0")}</span>:{" "}
            <span className="minutes">{String(minutes).padStart(2, "0")}</span>{" "}
            : <span className="second">{String(seconds).padStart(2, "0")}</span>
          </p>
        </div>
      );
    }
  };

  return (
    <Home />
  )

};

export default HomeScreen;
