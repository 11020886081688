import { ReactComponent as CheckIcon } from "../assets/img/Chield_check_fill.svg";

const FeatureSection = () => {
  return (
    <main
    id="botinfo"
      className="feature-container"
      style={{ opacity: "1" }}
    >
        
        <div className="ahre">

          <div className="feature_box" style={{marginRight: "50px"}}>
            <div style={{display: "flex", flexDirection: "row", alignItems: "center", margin: "50px auto 0", width: "fit-content"}}>
                <svg className="rotate" version="1.0" xmlns="http://www.w3.org/2000/svg"
                  width="25" height="25" viewBox="0 0 484.000000 490.000000"
                  preserveAspectRatio="xMidYMid meet">

                  <g transform="translate(0.000000,490.000000) scale(0.100000,-0.100000)"
                  fill="#fff" stroke="none">
                  <path d="M2255 4893 c-101 -7 -294 -34 -423 -60 -125 -24 -132 -27 -126 -47
                  20 -64 84 -216 136 -320 298 -596 727 -893 1385 -957 151 -15 600 -6 783 15
                  277 32 460 59 460 68 0 5 -27 52 -61 106 -546 868 -1247 1257 -2154 1195z"/>
                  <path d="M1153 4482 c-383 -361 -614 -622 -795 -897 -313 -476 -416 -995 -308
                  -1556 21 -109 77 -329 90 -348 10 -17 287 117 425 204 526 332 782 750 836
                  1365 14 162 7 510 -16 720 -27 252 -77 625 -85 634 -5 5 -71 -50 -147 -122z"/>
                  <path d="M4675 3229 c-406 -224 -713 -499 -904 -807 -164 -264 -244 -538 -266
                  -912 -14 -233 41 -695 116 -983 l10 -38 52 43 c774 645 998 914 1091 1310 43
                  182 51 273 51 608 0 297 -20 784 -34 823 -6 15 -20 9 -116 -44z"/>
                  <path d="M1190 1393 c-271 -18 -827 -77 -838 -88 -6 -6 74 -111 189 -250 112
                  -135 392 -414 509 -507 580 -460 1219 -619 2060 -512 69 9 126 17 128 19 6 6
                  -74 208 -129 324 -343 722 -859 1028 -1719 1019 -85 -1 -175 -3 -200 -5z"/>
                  </g>
                </svg>
                <h3 style={{color: "var(--white)",textAlign: "center", fontSize: "20px", display: "inline-block", marginRight: "25px", marginLeft: "25px"}}>BOT INFO</h3>
                <svg className="rotate" version="1.0" xmlns="http://www.w3.org/2000/svg"
                  width="25" height="25" viewBox="0 0 484.000000 490.000000"
                  preserveAspectRatio="xMidYMid meet">

                  <g transform="translate(0.000000,490.000000) scale(0.100000,-0.100000)"
                  fill="#fff" stroke="none">
                  <path d="M2255 4893 c-101 -7 -294 -34 -423 -60 -125 -24 -132 -27 -126 -47
                  20 -64 84 -216 136 -320 298 -596 727 -893 1385 -957 151 -15 600 -6 783 15
                  277 32 460 59 460 68 0 5 -27 52 -61 106 -546 868 -1247 1257 -2154 1195z"/>
                  <path d="M1153 4482 c-383 -361 -614 -622 -795 -897 -313 -476 -416 -995 -308
                  -1556 21 -109 77 -329 90 -348 10 -17 287 117 425 204 526 332 782 750 836
                  1365 14 162 7 510 -16 720 -27 252 -77 625 -85 634 -5 5 -71 -50 -147 -122z"/>
                  <path d="M4675 3229 c-406 -224 -713 -499 -904 -807 -164 -264 -244 -538 -266
                  -912 -14 -233 41 -695 116 -983 l10 -38 52 43 c774 645 998 914 1091 1310 43
                  182 51 273 51 608 0 297 -20 784 -34 823 -6 15 -20 9 -116 -44z"/>
                  <path d="M1190 1393 c-271 -18 -827 -77 -838 -88 -6 -6 74 -111 189 -250 112
                  -135 392 -414 509 -507 580 -460 1219 -619 2060 -512 69 9 126 17 128 19 6 6
                  -74 208 -129 324 -343 722 -859 1028 -1719 1019 -85 -1 -175 -3 -200 -5z"/>
                  </g>
                </svg>
              </div>
            <p className="feature_text">
              Smart Liquidity and Low Profits to Secure our Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum <a target={"_blank"} href={process.env.REACT_APP_BNB_FACTOR_CONRACT} className="text-violeta bold" style={{marginLeft: "1px"}}>Liquidity</a>
            </p>
          </div>

          <div className="feature_box">
            <div style={{display: "flex", flexDirection: "row", alignItems: "center", margin: "50px auto 0", width: "fit-content"}}>
                <svg className="rotate" version="1.0" xmlns="http://www.w3.org/2000/svg"
                  width="25" height="25" viewBox="0 0 484.000000 490.000000"
                  preserveAspectRatio="xMidYMid meet">

                  <g transform="translate(0.000000,490.000000) scale(0.100000,-0.100000)"
                  fill="#fff" stroke="none">
                  <path d="M2255 4893 c-101 -7 -294 -34 -423 -60 -125 -24 -132 -27 -126 -47
                  20 -64 84 -216 136 -320 298 -596 727 -893 1385 -957 151 -15 600 -6 783 15
                  277 32 460 59 460 68 0 5 -27 52 -61 106 -546 868 -1247 1257 -2154 1195z"/>
                  <path d="M1153 4482 c-383 -361 -614 -622 -795 -897 -313 -476 -416 -995 -308
                  -1556 21 -109 77 -329 90 -348 10 -17 287 117 425 204 526 332 782 750 836
                  1365 14 162 7 510 -16 720 -27 252 -77 625 -85 634 -5 5 -71 -50 -147 -122z"/>
                  <path d="M4675 3229 c-406 -224 -713 -499 -904 -807 -164 -264 -244 -538 -266
                  -912 -14 -233 41 -695 116 -983 l10 -38 52 43 c774 645 998 914 1091 1310 43
                  182 51 273 51 608 0 297 -20 784 -34 823 -6 15 -20 9 -116 -44z"/>
                  <path d="M1190 1393 c-271 -18 -827 -77 -838 -88 -6 -6 74 -111 189 -250 112
                  -135 392 -414 509 -507 580 -460 1219 -619 2060 -512 69 9 126 17 128 19 6 6
                  -74 208 -129 324 -343 722 -859 1028 -1719 1019 -85 -1 -175 -3 -200 -5z"/>
                  </g>
                </svg>
                <h3 style={{color: "var(--white)",textAlign: "center", fontSize: "20px", display: "inline-block", marginRight: "25px", marginLeft: "25px"}}>BOT INFO</h3>
                <svg className="rotate" version="1.0" xmlns="http://www.w3.org/2000/svg"
                  width="25" height="25" viewBox="0 0 484.000000 490.000000"
                  preserveAspectRatio="xMidYMid meet">

                  <g transform="translate(0.000000,490.000000) scale(0.100000,-0.100000)"
                  fill="#fff" stroke="none">
                  <path d="M2255 4893 c-101 -7 -294 -34 -423 -60 -125 -24 -132 -27 -126 -47
                  20 -64 84 -216 136 -320 298 -596 727 -893 1385 -957 151 -15 600 -6 783 15
                  277 32 460 59 460 68 0 5 -27 52 -61 106 -546 868 -1247 1257 -2154 1195z"/>
                  <path d="M1153 4482 c-383 -361 -614 -622 -795 -897 -313 -476 -416 -995 -308
                  -1556 21 -109 77 -329 90 -348 10 -17 287 117 425 204 526 332 782 750 836
                  1365 14 162 7 510 -16 720 -27 252 -77 625 -85 634 -5 5 -71 -50 -147 -122z"/>
                  <path d="M4675 3229 c-406 -224 -713 -499 -904 -807 -164 -264 -244 -538 -266
                  -912 -14 -233 41 -695 116 -983 l10 -38 52 43 c774 645 998 914 1091 1310 43
                  182 51 273 51 608 0 297 -20 784 -34 823 -6 15 -20 9 -116 -44z"/>
                  <path d="M1190 1393 c-271 -18 -827 -77 -838 -88 -6 -6 74 -111 189 -250 112
                  -135 392 -414 509 -507 580 -460 1219 -619 2060 -512 69 9 126 17 128 19 6 6
                  -74 208 -129 324 -343 722 -859 1028 -1719 1019 -85 -1 -175 -3 -200 -5z"/>
                  </g>
                </svg>
              </div>
            <p className="feature_text">
              Smart Liquidity and Low Profits to Secure our Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum <a target={"_blank"} href={process.env.REACT_APP_BNB_FACTOR_CONRACT} className="text-violeta bold" style={{marginLeft: "1px"}}>Liquidity</a>
            </p>
          </div>

        </div>

        <div className="feature_box">
            <div style={{display: "flex", flexDirection: "row", alignItems: "center", margin: "50px auto 0", width: "fit-content"}}>
                <svg className="rotate" version="1.0" xmlns="http://www.w3.org/2000/svg"
                  width="25" height="25" viewBox="0 0 484.000000 490.000000"
                  preserveAspectRatio="xMidYMid meet">

                  <g transform="translate(0.000000,490.000000) scale(0.100000,-0.100000)"
                  fill="#fff" stroke="none">
                  <path d="M2255 4893 c-101 -7 -294 -34 -423 -60 -125 -24 -132 -27 -126 -47
                  20 -64 84 -216 136 -320 298 -596 727 -893 1385 -957 151 -15 600 -6 783 15
                  277 32 460 59 460 68 0 5 -27 52 -61 106 -546 868 -1247 1257 -2154 1195z"/>
                  <path d="M1153 4482 c-383 -361 -614 -622 -795 -897 -313 -476 -416 -995 -308
                  -1556 21 -109 77 -329 90 -348 10 -17 287 117 425 204 526 332 782 750 836
                  1365 14 162 7 510 -16 720 -27 252 -77 625 -85 634 -5 5 -71 -50 -147 -122z"/>
                  <path d="M4675 3229 c-406 -224 -713 -499 -904 -807 -164 -264 -244 -538 -266
                  -912 -14 -233 41 -695 116 -983 l10 -38 52 43 c774 645 998 914 1091 1310 43
                  182 51 273 51 608 0 297 -20 784 -34 823 -6 15 -20 9 -116 -44z"/>
                  <path d="M1190 1393 c-271 -18 -827 -77 -838 -88 -6 -6 74 -111 189 -250 112
                  -135 392 -414 509 -507 580 -460 1219 -619 2060 -512 69 9 126 17 128 19 6 6
                  -74 208 -129 324 -343 722 -859 1028 -1719 1019 -85 -1 -175 -3 -200 -5z"/>
                  </g>
                </svg>
                <h3 style={{color: "var(--white)",textAlign: "center", fontSize: "20px", display: "inline-block", marginRight: "25px", marginLeft: "25px"}}>BOT INFO</h3>
                <svg className="rotate" version="1.0" xmlns="http://www.w3.org/2000/svg"
                  width="25" height="25" viewBox="0 0 484.000000 490.000000"
                  preserveAspectRatio="xMidYMid meet">

                  <g transform="translate(0.000000,490.000000) scale(0.100000,-0.100000)"
                  fill="#fff" stroke="none">
                  <path d="M2255 4893 c-101 -7 -294 -34 -423 -60 -125 -24 -132 -27 -126 -47
                  20 -64 84 -216 136 -320 298 -596 727 -893 1385 -957 151 -15 600 -6 783 15
                  277 32 460 59 460 68 0 5 -27 52 -61 106 -546 868 -1247 1257 -2154 1195z"/>
                  <path d="M1153 4482 c-383 -361 -614 -622 -795 -897 -313 -476 -416 -995 -308
                  -1556 21 -109 77 -329 90 -348 10 -17 287 117 425 204 526 332 782 750 836
                  1365 14 162 7 510 -16 720 -27 252 -77 625 -85 634 -5 5 -71 -50 -147 -122z"/>
                  <path d="M4675 3229 c-406 -224 -713 -499 -904 -807 -164 -264 -244 -538 -266
                  -912 -14 -233 41 -695 116 -983 l10 -38 52 43 c774 645 998 914 1091 1310 43
                  182 51 273 51 608 0 297 -20 784 -34 823 -6 15 -20 9 -116 -44z"/>
                  <path d="M1190 1393 c-271 -18 -827 -77 -838 -88 -6 -6 74 -111 189 -250 112
                  -135 392 -414 509 -507 580 -460 1219 -619 2060 -512 69 9 126 17 128 19 6 6
                  -74 208 -129 324 -343 722 -859 1028 -1719 1019 -85 -1 -175 -3 -200 -5z"/>
                  </g>
                </svg>
              </div>
            <p className="feature_text">
              Smart Liquidity and Low Profits to Secure our Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum <a target={"_blank"} href={process.env.REACT_APP_BNB_FACTOR_CONRACT} className="text-violeta bold" style={{marginLeft: "1px"}}>Liquidity</a>
            </p>
          </div>

    </main>
  );
};

export default FeatureSection;
