import BlockchainContext from "../store/BlockchainContext";
import { useContext, useEffect, useState } from "react";
import { walletAddressSlicer, numberWithCommas } from "../utils/util";
import { toast } from "react-toastify";

const BuyABot = () => {

  const d = document;

  const botLevel = localStorage.getItem("web_cache_ls11");

  const [amount = 0.01, setAmount] = useState();  // Monto del Donate
  const { handleDonate, donatepending, donateText, approveBusd, array } =
    useContext(BlockchainContext);

  const donate = () => {
    if (new Date().getTime() > 1652554800000) {
      if (donateText === "Enable") {
        approveBusd(account);
      } else {
        if (Number(amount) > 0) {
          handleDonate(amount);
        } else {
          toast.error("Enter valid amount", {
            position: "bottom-right",
          });
        }
      }
    } else {
      toast.warn("Launch date is Saturday 14th May 2022 at 7pm UTC", {
        position: "bottom-right",
      });
    }
  };

  const donateUSDT = () => {
    d.querySelector(".donateBNB").classList.add("display-none");
    d.querySelector(".donateBNB").classList.add("v-none");
    d.querySelector(".donateUSDT").classList.remove("display-none");
    d.querySelector(".donateUSDT").classList.remove("v-none");

    d.querySelector(".bnb-button").classList.remove("selected");
    d.querySelector(".usdt-button").classList.add("selected");
  }

  const donateBNB = () => {
    d.querySelector(".donateUSDT").classList.add("display-none");
    d.querySelector(".donateUSDT").classList.add("v-none");
    d.querySelector(".donateBNB").classList.remove("display-none");
    d.querySelector(".donateBNB").classList.remove("v-none");

    d.querySelector(".bnb-button").classList.add("selected");
    d.querySelector(".usdt-button").classList.remove("selected");
  }

  const [open, setOpen] = useState(false);
  const { handleConnectToWallet, account, handleDisconnectWallet } =
    useContext(BlockchainContext);

    
    if (account && (Number(botLevel) >= 1 && Number(botLevel) < 4)) {
        return (
            <>
            <div id="buyabot">
             <div style={{display: "flex", flexDirection: "row", alignItems: "center", margin: "50px auto 0", width: "fit-content"}}>
              <svg className="rotate" version="1.0" xmlns="http://www.w3.org/2000/svg"
                width="30" height="30" viewBox="0 0 484.000000 490.000000"
                preserveAspectRatio="xMidYMid meet">

                <g transform="translate(0.000000,490.000000) scale(0.100000,-0.100000)"
                fill="#fff" stroke="none">
                <path d="M2255 4893 c-101 -7 -294 -34 -423 -60 -125 -24 -132 -27 -126 -47
                20 -64 84 -216 136 -320 298 -596 727 -893 1385 -957 151 -15 600 -6 783 15
                277 32 460 59 460 68 0 5 -27 52 -61 106 -546 868 -1247 1257 -2154 1195z"/>
                <path d="M1153 4482 c-383 -361 -614 -622 -795 -897 -313 -476 -416 -995 -308
                -1556 21 -109 77 -329 90 -348 10 -17 287 117 425 204 526 332 782 750 836
                1365 14 162 7 510 -16 720 -27 252 -77 625 -85 634 -5 5 -71 -50 -147 -122z"/>
                <path d="M4675 3229 c-406 -224 -713 -499 -904 -807 -164 -264 -244 -538 -266
                -912 -14 -233 41 -695 116 -983 l10 -38 52 43 c774 645 998 914 1091 1310 43
                182 51 273 51 608 0 297 -20 784 -34 823 -6 15 -20 9 -116 -44z"/>
                <path d="M1190 1393 c-271 -18 -827 -77 -838 -88 -6 -6 74 -111 189 -250 112
                -135 392 -414 509 -507 580 -460 1219 -619 2060 -512 69 9 126 17 128 19 6 6
                -74 208 -129 324 -343 722 -859 1028 -1719 1019 -85 -1 -175 -3 -200 -5z"/>
                </g>
              </svg>
              <h2 style={{color: "var(--white)", fontSize: "30px", display: "inline-block", marginRight: "25px", marginLeft: "25px"}}>BUY A BOT </h2>
              <svg className="rotate" version="1.0" xmlns="http://www.w3.org/2000/svg"
                width="30" height="30" viewBox="0 0 484.000000 490.000000"
                preserveAspectRatio="xMidYMid meet">

                <g transform="translate(0.000000,490.000000) scale(0.100000,-0.100000)"
                fill="#fff" stroke="none">
                <path d="M2255 4893 c-101 -7 -294 -34 -423 -60 -125 -24 -132 -27 -126 -47
                20 -64 84 -216 136 -320 298 -596 727 -893 1385 -957 151 -15 600 -6 783 15
                277 32 460 59 460 68 0 5 -27 52 -61 106 -546 868 -1247 1257 -2154 1195z"/>
                <path d="M1153 4482 c-383 -361 -614 -622 -795 -897 -313 -476 -416 -995 -308
                -1556 21 -109 77 -329 90 -348 10 -17 287 117 425 204 526 332 782 750 836
                1365 14 162 7 510 -16 720 -27 252 -77 625 -85 634 -5 5 -71 -50 -147 -122z"/>
                <path d="M4675 3229 c-406 -224 -713 -499 -904 -807 -164 -264 -244 -538 -266
                -912 -14 -233 41 -695 116 -983 l10 -38 52 43 c774 645 998 914 1091 1310 43
                182 51 273 51 608 0 297 -20 784 -34 823 -6 15 -20 9 -116 -44z"/>
                <path d="M1190 1393 c-271 -18 -827 -77 -838 -88 -6 -6 74 -111 189 -250 112
                -135 392 -414 509 -507 580 -460 1219 -619 2060 -512 69 9 126 17 128 19 6 6
                -74 208 -129 324 -343 722 -859 1028 -1719 1019 -85 -1 -175 -3 -200 -5z"/>
                </g>
              </svg>
            </div>
          </div>

          {/* DONATE USDT */}

          <div className="donateUSDT v-none display-none">
              <div className="stake-bottom stake-bottom-home">
                <div className="stake-bottom-item stake-bottom-item-home">
                  <div className="input-logo">
                    <img src="./assets/img/ninjausdt.png" className="floating-leve" style={{width: "150px", position: "relative" ,top: "-2.5px", margin: "auto"}}></img>
                    <div className="relative no-selection inputtt" style={{display: "none"}}>
                      <input
                        className="input-stake "
                        id="input-stake-home"
                        type="text"
                        value={amount}
                        step={0.00001}
                        disabled={true}
                        onChange={(event) => {
                          if (event.target.value >= 0 || event.target.value === "") {
                            setAmount(event.target.value); // Ningun error
                          }
                        }}
                      />
                      <span
                        className="input-current"
                      >
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 2000 2000" width="30" height="30"><path d="M1000,0c552.26,0,1000,447.74,1000,1000S1552.24,2000,1000,2000,0,1552.38,0,1000,447.68,0,1000,0" fill="#53ae94"/><path d="M1123.42,866.76V718H1463.6V491.34H537.28V718H877.5V866.64C601,879.34,393.1,934.1,393.1,999.7s208,120.36,484.4,133.14v476.5h246V1132.8c276-12.74,483.48-67.46,483.48-133s-207.48-120.26-483.48-133m0,225.64v-0.12c-6.94.44-42.6,2.58-122,2.58-63.48,0-108.14-1.8-123.88-2.62v0.2C633.34,1081.66,451,1039.12,451,988.22S633.36,894.84,877.62,884V1050.1c16,1.1,61.76,3.8,124.92,3.8,75.86,0,114-3.16,121-3.8V884c243.8,10.86,425.72,53.44,425.72,104.16s-182,93.32-425.72,104.18" fill="#fff"/></svg>
                      </span>
                      <button
                        type="submit"
                        onClick={() => donate()}
                        className="button-secondary invest"
                        style={{backgroundColor: "var(--usdt)", marginLeft: "25px", borderRadius: "0 0 5px 5px", fontWeight: "400"}}
                      >
                        {donatepending ? <>{"PENDING..."}</> : <>{"LEVEL UP"}</>}
                      </button>
                    </div>
                  </div>
                  <span
                    id="input-stake-home-span"
                    style={{ color: "black", textAlign: "center" }}
                  >
                    Invalid Amount
                  </span>
                  <div className="">
                  </div>
                  
                  <div className="tokens-list">
                    <button className="bnb-button" style={{ minWidth: "125px"}} onClick={() => donateBNB()}>
                      <img src="./assets/img/bnb-logo.png" style={{width: "100px", height: "125px", minWidth: "125px"}}></img>
                    </button>
                    <button className="selected usdt-button" autoFocus onClick={() => donateUSDT()} style={{outline: "none", minWidth: "125px"}}>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 2000 2000" width="125" height="125"><path d="M1000,0c552.26,0,1000,447.74,1000,1000S1552.24,2000,1000,2000,0,1552.38,0,1000,447.68,0,1000,0" fill="#53ae94" /><path d="M1123.42,866.76V718H1463.6V491.34H537.28V718H877.5V866.64C601,879.34,393.1,934.1,393.1,999.7s208,120.36,484.4,133.14v476.5h246V1132.8c276-12.74,483.48-67.46,483.48-133s-207.48-120.26-483.48-133m0,225.64v-0.12c-6.94.44-42.6,2.58-122,2.58-63.48,0-108.14-1.8-123.88-2.62v0.2C633.34,1081.66,451,1039.12,451,988.22S633.36,894.84,877.62,884V1050.1c16,1.1,61.76,3.8,124.92,3.8,75.86,0,114-3.16,121-3.8V884c243.8,10.86,425.72,53.44,425.72,104.16s-182,93.32-425.72,104.18" fill="#fff" /></svg>
                    </button>
                  </div>
                  <p style={{margin: "0px auto 0", width: "fit-content",color: "var(--white)", fontSize: "18px"}}>COMING SOON!</p>
                  <div className="coming-bots" style={{visibility: "hidden"}}>
                    <img src="./assets/img/binance-usd-busd-logo.png" width={"25px"} height={"30px"}></img>
                    <img src="./assets/img/solana-sol-logo.png" width={"25px"} height={"30px"}></img>
                    <img src="./assets/img/eth.png" width={"23px"} style={{height: "26px", objectFit: "inherit"}}></img>
                  </div>
                </div>
                
              </div>
            </div>

          {/* DONATE BNB */}

            <div className="donateBNB ">
              <div className="stake-bottom stake-bottom-home">
                <div className="stake-bottom-item stake-bottom-item-home">
                  <div className="input-logo">
                    <img src="./assets/img/ninjabnb.png" className="floating-leve" style={{width: "150px", position: "relative" ,top: "-2.5px"}}></img>
                    <div className="relative no-selection inputtt">
                      <input
                        className="input-stake "
                        id="input-stake-home"
                        type="text"
                        value={amount}
                        step={0.00001}
                        disabled={true}
                        onChange={(event) => {
                          if (event.target.value >= 0 || event.target.value === "") {
                            setAmount(event.target.value); // Ningun error
                          }
                        }}
                      />
                      <span
                        className="input-current"
                      >
                        <img src="./assets/img/bnb-logo.png" style={{width: "30px", height: "30px"}}></img>
                      </span>
                      <button
                      type="submit"
                      onClick={() => donate()}
                      className="button-secondary invest"
                      style={{backgroundColor: "var(--bnb)", marginLeft: "25px", borderRadius: "0 0 5px 5px", fontWeight: "400"}}
                    >
                      {donatepending ? <>{"PENDING..."}</> : <>{"LEVEL UP"}</>}
                    </button>
                    </div>
                  </div>
                  <span
                    id="input-stake-home-span"
                    style={{ color: "black", textAlign: "center" }}
                  >
                    Invalid Amount
                  </span>
                  <div className="">
                  </div>
                  
                  <div className="tokens-list">
                    <button className="bnb-button selected" style={{ minWidth: "125px"}} onClick={() => donateBNB()}>
                      <img src="./assets/img/bnb-logo.png" style={{width: "125px", height: "125px", minWidth: "125px"}}></img>
                    </button>
                    <button className="usdt-button" autoFocus onClick={() => donateUSDT()} style={{outline: "none", minWidth: "125px"}}>
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 2000 2000" width="125" height="125"><path d="M1000,0c552.26,0,1000,447.74,1000,1000S1552.24,2000,1000,2000,0,1552.38,0,1000,447.68,0,1000,0" fill="#53ae94" /><path d="M1123.42,866.76V718H1463.6V491.34H537.28V718H877.5V866.64C601,879.34,393.1,934.1,393.1,999.7s208,120.36,484.4,133.14v476.5h246V1132.8c276-12.74,483.48-67.46,483.48-133s-207.48-120.26-483.48-133m0,225.64v-0.12c-6.94.44-42.6,2.58-122,2.58-63.48,0-108.14-1.8-123.88-2.62v0.2C633.34,1081.66,451,1039.12,451,988.22S633.36,894.84,877.62,884V1050.1c16,1.1,61.76,3.8,124.92,3.8,75.86,0,114-3.16,121-3.8V884c243.8,10.86,425.72,53.44,425.72,104.16s-182,93.32-425.72,104.18" fill="#fff" /></svg>
                    </button>
                  </div>
                  <p style={{margin: "0px auto 0", width: "fit-content",color: "var(--white)", fontSize: "18px"}}>MORE BOTS COMING SOON!</p>
                  <div className="coming-bots">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 2000 2000" width="27" height="27"><path d="M1000,0c552.26,0,1000,447.74,1000,1000S1552.24,2000,1000,2000,0,1552.38,0,1000,447.68,0,1000,0" fill="#53ae94" /><path d="M1123.42,866.76V718H1463.6V491.34H537.28V718H877.5V866.64C601,879.34,393.1,934.1,393.1,999.7s208,120.36,484.4,133.14v476.5h246V1132.8c276-12.74,483.48-67.46,483.48-133s-207.48-120.26-483.48-133m0,225.64v-0.12c-6.94.44-42.6,2.58-122,2.58-63.48,0-108.14-1.8-123.88-2.62v0.2C633.34,1081.66,451,1039.12,451,988.22S633.36,894.84,877.62,884V1050.1c16,1.1,61.76,3.8,124.92,3.8,75.86,0,114-3.16,121-3.8V884c243.8,10.86,425.72,53.44,425.72,104.16s-182,93.32-425.72,104.18" fill="#fff" /></svg>
                    <img src="./assets/img/binance-usd-busd-logo.png" width={"25px"} height={"30px"}></img>
                    <img src="./assets/img/solana-sol-logo.png" width={"25px"} height={"30px"}></img>
                    <img src="./assets/img/eth.png" width={"23px"} style={{height: "26px", objectFit: "inherit"}}></img>
                  </div>
                </div>
              </div>
            </div>
        </>
        )
    } else {
        return (
            <>
            <div id="buyabot">
             <div style={{display: "flex", flexDirection: "row", alignItems: "center", margin: "50px auto 0", width: "fit-content"}}>
              <svg className="rotate" version="1.0" xmlns="http://www.w3.org/2000/svg"
                width="30" height="30" viewBox="0 0 484.000000 490.000000"
                preserveAspectRatio="xMidYMid meet">

                <g transform="translate(0.000000,490.000000) scale(0.100000,-0.100000)"
                fill="#fff" stroke="none">
                <path d="M2255 4893 c-101 -7 -294 -34 -423 -60 -125 -24 -132 -27 -126 -47
                20 -64 84 -216 136 -320 298 -596 727 -893 1385 -957 151 -15 600 -6 783 15
                277 32 460 59 460 68 0 5 -27 52 -61 106 -546 868 -1247 1257 -2154 1195z"/>
                <path d="M1153 4482 c-383 -361 -614 -622 -795 -897 -313 -476 -416 -995 -308
                -1556 21 -109 77 -329 90 -348 10 -17 287 117 425 204 526 332 782 750 836
                1365 14 162 7 510 -16 720 -27 252 -77 625 -85 634 -5 5 -71 -50 -147 -122z"/>
                <path d="M4675 3229 c-406 -224 -713 -499 -904 -807 -164 -264 -244 -538 -266
                -912 -14 -233 41 -695 116 -983 l10 -38 52 43 c774 645 998 914 1091 1310 43
                182 51 273 51 608 0 297 -20 784 -34 823 -6 15 -20 9 -116 -44z"/>
                <path d="M1190 1393 c-271 -18 -827 -77 -838 -88 -6 -6 74 -111 189 -250 112
                -135 392 -414 509 -507 580 -460 1219 -619 2060 -512 69 9 126 17 128 19 6 6
                -74 208 -129 324 -343 722 -859 1028 -1719 1019 -85 -1 -175 -3 -200 -5z"/>
                </g>
              </svg>
              <h2 style={{color: "var(--white)", fontSize: "30px", display: "inline-block", marginRight: "25px", marginLeft: "25px"}}>BUY A BOT </h2>
              <svg className="rotate" version="1.0" xmlns="http://www.w3.org/2000/svg"
                width="30" height="30" viewBox="0 0 484.000000 490.000000"
                preserveAspectRatio="xMidYMid meet">

                <g transform="translate(0.000000,490.000000) scale(0.100000,-0.100000)"
                fill="#fff" stroke="none">
                <path d="M2255 4893 c-101 -7 -294 -34 -423 -60 -125 -24 -132 -27 -126 -47
                20 -64 84 -216 136 -320 298 -596 727 -893 1385 -957 151 -15 600 -6 783 15
                277 32 460 59 460 68 0 5 -27 52 -61 106 -546 868 -1247 1257 -2154 1195z"/>
                <path d="M1153 4482 c-383 -361 -614 -622 -795 -897 -313 -476 -416 -995 -308
                -1556 21 -109 77 -329 90 -348 10 -17 287 117 425 204 526 332 782 750 836
                1365 14 162 7 510 -16 720 -27 252 -77 625 -85 634 -5 5 -71 -50 -147 -122z"/>
                <path d="M4675 3229 c-406 -224 -713 -499 -904 -807 -164 -264 -244 -538 -266
                -912 -14 -233 41 -695 116 -983 l10 -38 52 43 c774 645 998 914 1091 1310 43
                182 51 273 51 608 0 297 -20 784 -34 823 -6 15 -20 9 -116 -44z"/>
                <path d="M1190 1393 c-271 -18 -827 -77 -838 -88 -6 -6 74 -111 189 -250 112
                -135 392 -414 509 -507 580 -460 1219 -619 2060 -512 69 9 126 17 128 19 6 6
                -74 208 -129 324 -343 722 -859 1028 -1719 1019 -85 -1 -175 -3 -200 -5z"/>
                </g>
              </svg>
            </div>
          </div>

          {/* DONATE USDT */}

          <div className="donateUSDT v-none display-none">
              <div className="stake-bottom stake-bottom-home">
                <div className="stake-bottom-item stake-bottom-item-home">
                  <div className="input-logo">
                    <img src="./assets/img/ninjausdt.png" className="floating-leve" style={{width: "150px", position: "relative" ,top: "-2.5px", margin: "auto"}}></img>
                    <div className="relative no-selection inputtt" style={{display: "none"}}>
                      <input
                        className="input-stake "
                        id="input-stake-home"
                        type="text"
                        value={amount}
                        step={0.00001}
                        disabled={true}
                        onChange={(event) => {
                          if (event.target.value >= 0 || event.target.value === "") {
                            setAmount(event.target.value); // Ningun error
                          }
                        }}
                      />
                      <span
                        className="input-current"
                      >
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 2000 2000" width="30" height="30"><path d="M1000,0c552.26,0,1000,447.74,1000,1000S1552.24,2000,1000,2000,0,1552.38,0,1000,447.68,0,1000,0" fill="#53ae94"/><path d="M1123.42,866.76V718H1463.6V491.34H537.28V718H877.5V866.64C601,879.34,393.1,934.1,393.1,999.7s208,120.36,484.4,133.14v476.5h246V1132.8c276-12.74,483.48-67.46,483.48-133s-207.48-120.26-483.48-133m0,225.64v-0.12c-6.94.44-42.6,2.58-122,2.58-63.48,0-108.14-1.8-123.88-2.62v0.2C633.34,1081.66,451,1039.12,451,988.22S633.36,894.84,877.62,884V1050.1c16,1.1,61.76,3.8,124.92,3.8,75.86,0,114-3.16,121-3.8V884c243.8,10.86,425.72,53.44,425.72,104.16s-182,93.32-425.72,104.18" fill="#fff"/></svg>
                      </span>
                      <button
                        type="submit"
                        onClick={() => donate()}
                        className="button-secondary invest"
                        style={{backgroundColor: "var(--usdt)", marginLeft: "25px", borderRadius: "0 0 5px 5px", fontWeight: "400"}}
                      >
                        {donatepending ? <>{"PENDING..."}</> : <>{"BUY"}</>}
                      </button>
                    </div>
                  </div>
                  <span
                    id="input-stake-home-span"
                    style={{ color: "black", textAlign: "center" }}
                  >
                    Invalid Amount
                  </span>
                  <div className="">
                  </div>
                  
                  <div className="tokens-list">
                    <button className="bnb-button" style={{ minWidth: "125px"}} onClick={() => donateBNB()}>
                      <img src="./assets/img/bnb-logo.png" style={{width: "100px", height: "125px", minWidth: "125px"}}></img>
                    </button>
                    <button className="selected usdt-button" autoFocus onClick={() => donateUSDT()} style={{outline: "none", minWidth: "125px"}}>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 2000 2000" width="125" height="125"><path d="M1000,0c552.26,0,1000,447.74,1000,1000S1552.24,2000,1000,2000,0,1552.38,0,1000,447.68,0,1000,0" fill="#53ae94" /><path d="M1123.42,866.76V718H1463.6V491.34H537.28V718H877.5V866.64C601,879.34,393.1,934.1,393.1,999.7s208,120.36,484.4,133.14v476.5h246V1132.8c276-12.74,483.48-67.46,483.48-133s-207.48-120.26-483.48-133m0,225.64v-0.12c-6.94.44-42.6,2.58-122,2.58-63.48,0-108.14-1.8-123.88-2.62v0.2C633.34,1081.66,451,1039.12,451,988.22S633.36,894.84,877.62,884V1050.1c16,1.1,61.76,3.8,124.92,3.8,75.86,0,114-3.16,121-3.8V884c243.8,10.86,425.72,53.44,425.72,104.16s-182,93.32-425.72,104.18" fill="#fff" /></svg>
                    </button>
                  </div>
                  <p style={{margin: "0px auto 0", width: "fit-content",color: "var(--white)", fontSize: "18px"}}>COMING SOON!</p>
                  <div className="coming-bots" style={{visibility: "hidden"}}>
                    <img src="./assets/img/binance-usd-busd-logo.png" width={"25px"} height={"30px"}></img>
                    <img src="./assets/img/solana-sol-logo.png" width={"25px"} height={"30px"}></img>
                    <img src="./assets/img/eth.png" width={"23px"} style={{height: "26px", objectFit: "inherit"}}></img>
                  </div>
                </div>
                
              </div>
            </div>

          {/* DONATE BNB */}

            <div className="donateBNB ">
              <div className="stake-bottom stake-bottom-home">
                <div className="stake-bottom-item stake-bottom-item-home">
                  <div className="input-logo">
                    <img src="./assets/img/ninjabnb.png" className="floating-leve" style={{width: "150px", position: "relative" ,top: "-2.5px"}}></img>
                    <div className="relative no-selection inputtt">
                      <input
                        className="input-stake "
                        id="input-stake-home"
                        type="text"
                        value={amount}
                        step={0.00001}
                        disabled={true}
                        onChange={(event) => {
                          if (event.target.value >= 0 || event.target.value === "") {
                            setAmount(event.target.value); // Ningun error
                          }
                        }}
                      />
                      <span
                        className="input-current"
                      >
                        <img src="./assets/img/bnb-logo.png" style={{width: "30px", height: "30px"}}></img>
                      </span>
                      <button
                      type="submit"
                      onClick={() => donate()}
                      className="button-secondary invest"
                      style={{backgroundColor: "var(--bnb)", marginLeft: "25px", borderRadius: "0 0 5px 5px", fontWeight: "400"}}
                    >
                      {donatepending ? <>{"PENDING..."}</> : <>{"BUY"}</>}
                    </button>
                    </div>
                  </div>
                  <span
                    id="input-stake-home-span"
                    style={{ color: "black", textAlign: "center" }}
                  >
                    Invalid Amount
                  </span>
                  <div className="">
                  </div>
                  
                  <div className="tokens-list">
                    <button className="bnb-button selected" style={{ minWidth: "125px"}} onClick={() => donateBNB()}>
                      <img src="./assets/img/bnb-logo.png" style={{width: "125px", height: "125px", minWidth: "125px"}}></img>
                    </button>
                    <button className="usdt-button" autoFocus onClick={() => donateUSDT()} style={{outline: "none", minWidth: "125px"}}>
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 2000 2000" width="125" height="125"><path d="M1000,0c552.26,0,1000,447.74,1000,1000S1552.24,2000,1000,2000,0,1552.38,0,1000,447.68,0,1000,0" fill="#53ae94" /><path d="M1123.42,866.76V718H1463.6V491.34H537.28V718H877.5V866.64C601,879.34,393.1,934.1,393.1,999.7s208,120.36,484.4,133.14v476.5h246V1132.8c276-12.74,483.48-67.46,483.48-133s-207.48-120.26-483.48-133m0,225.64v-0.12c-6.94.44-42.6,2.58-122,2.58-63.48,0-108.14-1.8-123.88-2.62v0.2C633.34,1081.66,451,1039.12,451,988.22S633.36,894.84,877.62,884V1050.1c16,1.1,61.76,3.8,124.92,3.8,75.86,0,114-3.16,121-3.8V884c243.8,10.86,425.72,53.44,425.72,104.16s-182,93.32-425.72,104.18" fill="#fff" /></svg>
                    </button>
                  </div>
                  <p style={{margin: "0px auto 0", width: "fit-content",color: "var(--white)", fontSize: "18px"}}>MORE BOTS COMING SOON!</p>
                  <div className="coming-bots">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 2000 2000" width="27" height="27"><path d="M1000,0c552.26,0,1000,447.74,1000,1000S1552.24,2000,1000,2000,0,1552.38,0,1000,447.68,0,1000,0" fill="#53ae94" /><path d="M1123.42,866.76V718H1463.6V491.34H537.28V718H877.5V866.64C601,879.34,393.1,934.1,393.1,999.7s208,120.36,484.4,133.14v476.5h246V1132.8c276-12.74,483.48-67.46,483.48-133s-207.48-120.26-483.48-133m0,225.64v-0.12c-6.94.44-42.6,2.58-122,2.58-63.48,0-108.14-1.8-123.88-2.62v0.2C633.34,1081.66,451,1039.12,451,988.22S633.36,894.84,877.62,884V1050.1c16,1.1,61.76,3.8,124.92,3.8,75.86,0,114-3.16,121-3.8V884c243.8,10.86,425.72,53.44,425.72,104.16s-182,93.32-425.72,104.18" fill="#fff" /></svg>
                    <img src="./assets/img/binance-usd-busd-logo.png" width={"25px"} height={"30px"}></img>
                    <img src="./assets/img/solana-sol-logo.png" width={"25px"} height={"30px"}></img>
                    <img src="./assets/img/eth.png" width={"23px"} style={{height: "26px", objectFit: "inherit"}}></img>
                  </div>
                </div>
              </div>
            </div>
        </>
        )
    }

}

export default BuyABot;